import React, { forwardRef, useState, useEffect, useRef } from "react";
import { format, isValid } from "date-fns";
import { forEach } from "lodash";
import axios from "axios";

const ChatSession = forwardRef(
    (
        {
            chatMessages,
            newMessage,
            setNewMessage,
            sendMessage,
            setFiles,
            sendMessageOnPressEnter,
            closeModal,
            openFilePicker,
            name,
        },
        ref,
    ) => {
        const { chatInputRef, fileInputRef } = ref;

        // State to track if files are selected
        const [hasSelectedFiles, setHasSelectedFiles] = useState(false);

        const [readMessages, setReadMessages] = useState({});

        const observer = useRef(null);

        const markMessageAsRead = async (messageId) => {
            // Call an API to mark the message as read

            console.log("Marking message as read:", messageId);

            const response = await axios.post("/mark-chat-message-as-read", {
                id: messageId,
            });

            console.log({ responseData: response?.data });
        };

        useEffect(() => {
            if (!observer.current) {
                observer.current = new IntersectionObserver(
                    (entries) => {
                        entries.forEach((entry) => {
                            if (entry.isIntersecting) {
                                const messageId =
                                    entry.target.dataset.messageId;
                                setReadMessages((prevReadMessages) => ({
                                    ...prevReadMessages,
                                    [messageId]: true,
                                }));
                                // Call an API to mark the message as read
                                markMessageAsRead(messageId);
                            }
                        });
                    },
                    {
                        rootMargin: "0px",
                        threshold: 1.0,
                    },
                );
            }

            const messageElements = document.querySelectorAll(".message");

            messageElements.forEach((messageElement) => {
                observer.current.observe(messageElement);
            });

            return () => {
                messageElements.forEach((messageElement) => {
                    observer.current.unobserve(messageElement);
                });
            };
        }, [chatMessages]);

        // Dedupe chatMessages by id
        const dedupedChatMessages = chatMessages.reduce((acc, msg) => {
            if (!acc.some((m) => m.id === msg.id)) {
                acc.push(msg);
            }
            return acc;
        }, []);

        return (
            <div className="flex flex-col justify-between flex-1 h-full p-2 overflow-scroll bg-white sm:p-6">
                <div className="flex justify-between py-3 border-b-2 border-gray-200 sm:items-center">
                    <div className="relative flex items-center space-x-4">
                        <div className="flex flex-col leading-tight">
                            <div className="flex items-center justify-center mt-1 text-2xl">
                                <span className="mr-3 text-gray-700">
                                    {name}
                                </span>
                            </div>
                            {/* <span className="text-lg text-gray-600">
                            Junior Developer
                        </span> */}
                        </div>
                    </div>
                    <div className="flex items-center space-x-2">
                        <button
                            type="button"
                            className="inline-flex items-center justify-center w-10 h-10 text-gray-500 transition duration-500 ease-in-out border rounded-lg hover:bg-gray-300 focus:outline-none"
                            onClick={closeModal}
                        >
                            {/* biome-ignore lint/a11y/noSvgWithoutTitle: <explanation> */}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                className="w-6 h-6"
                                role="img"
                                aria-labelledby="Chat Button"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
                <div
                    id="messages"
                    className="flex flex-col h-full p-3 space-y-4 overflow-y-auto scrolling-touch bg-slate-100 scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2"
                >
                    {dedupedChatMessages.map((msg, index) => {
                        const { last_message_time: time } = msg;

                        let msgDateTime = new Date(time);

                        const dynamicMsgDateTime = isValid(msgDateTime)
                            ? msgDateTime
                            : new Date();

                        const formattedDate = format(
                            dynamicMsgDateTime,
                            "MM-dd-yyyy 'at' hh:mm:ss a",
                        );

                        console.log({ msg });
                        if (!msg.last_message && !msg.file_path) return;

                        const fileExtension = msg.file_path?.file_url
                            ?.split(".")
                            .pop();

                        console.log({ fileExtension });
                        const imgExtensions = [
                            "jpg",
                            "jpeg",
                            "png",
                            "gif",
                            "webp",
                        ];
                        const videoExtensions = ["mp4", "webm", "ogg", "avi"];
                        const documentExtensions = [
                            "pdf",
                            "doc",
                            "docx",
                            "xls",
                            "xlsx",
                            "ppt",
                            "pptx",
                            "txt",
                            "csv",
                            "odt",
                        ];

                        const isImgExtension =
                            imgExtensions.includes(fileExtension);
                        const isVideoExtension =
                            videoExtensions.includes(fileExtension);
                        const isDocumentExtension =
                            documentExtensions.includes(fileExtension);

                        return (
                            <div
                                key={msg.id}
                                uuid={msg.session_id}
                                data-message-id={msg.id}
                                id={`message-${msg.id}`}
                                className={`chat-message flex ${msg?.user_id && msg?.to ? "justify-end" : !msg?.user_id && msg?.to ? "justify-start" : "justify-end"}`}
                            >
                                <div className="flex items-end">
                                    <div
                                        className={`flex flex-col order-2 max-w-xs mx-2 space-y-2 text-xs bg-slate-100`}
                                    >
                                        <div>
                                            <span className="inline-block px-4 py-2 text-gray-600 bg-white border border-gray-200 rounded-lg rounded-bl-none shadow-sm">
                                                <div>
                                                    {msg.last_message
                                                        ? msg.last_message
                                                        : ""}
                                                </div>
                                                {msg?.file_path?.file_url &&
                                                    isImgExtension && (
                                                        <img
                                                            src={
                                                                msg.file_path
                                                                    .file_url
                                                            }
                                                            alt="file"
                                                            className="w-5/6 rounded-md h-5/6"
                                                        />
                                                    )}
                                                {msg?.file_path?.file_url &&
                                                    isVideoExtension && (
                                                        <video
                                                            src={
                                                                msg.file_path
                                                                    .file_url
                                                            }
                                                            className="w-5/6 rounded-md h-5/6"
                                                            controls
                                                        />
                                                    )}
                                                {msg?.file_path?.file_url &&
                                                    isDocumentExtension && (
                                                        <a
                                                            href={
                                                                msg.file_path
                                                                    .file_url
                                                            }
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            download={true}
                                                            className="text-blue-500"
                                                        >
                                                            Download Link
                                                        </a>
                                                    )}
                                                <br />
                                                <span className="text-xs text-gray-500">
                                                    {formattedDate}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    {/* <img
                                        src="https://images.unsplash.com/photo-1549078642-b2ba4bda0cdb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=144&h=144"
                                        alt="My profile"
                                        className="order-1 w-6 h-6 rounded-full"
                                    /> */}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="pt-4 mb-2 border-t-2 border-gray-200 sm:mb-0">
                    <div className="relative flex">
                        {/* <span className="absolute inset-y-0 flex items-center">
                            <button
                                type="button"
                                className="inline-flex items-center justify-center w-12 h-12 text-gray-500 transition duration-500 ease-in-out rounded-full hover:bg-gray-300 focus:outline-none"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    className="w-6 h-6 text-gray-600"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z"
                                    ></path>
                                </svg>
                            </button>
                        </span> */}
                        <form
                            className="w-full text-gray-600 placeholder-gray-600 bg-gray-200 rounded-md focus:outline-none focus:placeholder-gray-400"
                            onKeyDown={sendMessageOnPressEnter}
                            onSubmit={(event) => {
                                event.preventDefault();
                            }}
                        >
                            <input
                                type="text"
                                placeholder="Write your message!"
                                className="w-full py-3 pl-4 text-gray-600 placeholder-gray-600 rounded-md bg-slate-100 focus:outline-none focus:placeholder-gray-400"
                                ref={chatInputRef}
                                value={newMessage}
                                onChange={(e) => {
                                    setNewMessage(e.target.value);
                                }}
                            />
                            <input
                                type="file"
                                style={{ display: "none" }}
                                ref={fileInputRef}
                                onChange={(event) => {
                                    // Handle file selection
                                    setFiles(event.target.files);

                                    if (event.target.files?.length > 0) {
                                        setHasSelectedFiles(true);
                                    }
                                }}
                            />
                            <div className="absolute inset-y-0 right-0 items-center hidden sm:flex">
                                <button
                                    type="button"
                                    className="relative inline-flex items-center justify-center w-10 h-10 text-gray-500 transition duration-500 ease-in-out rounded-full hover:bg-gray-300 focus:outline-none"
                                    onClick={openFilePicker}
                                >
                                    {hasSelectedFiles && (
                                        <span className="absolute top-0 left-0 flex items-center justify-center w-4 h-4 text-sm text-white bg-red-500 rounded-full">
                                            1
                                        </span>
                                    )}
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        className="w-6 h-6 text-gray-600"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                                        ></path>
                                    </svg>
                                </button>
                                {/* <button
                                type="button"
                                className="inline-flex items-center justify-center w-10 h-10 text-gray-500 transition duration-500 ease-in-out rounded-full hover:bg-gray-300 focus:outline-none"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    className="w-6 h-6 text-gray-600"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
                                    ></path>
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
                                    ></path>
                                </svg>
                            </button> */}
                                {/* <button
                                type="button"
                                className="inline-flex items-center justify-center w-10 h-10 text-gray-500 transition duration-500 ease-in-out rounded-full hover:bg-gray-300 focus:outline-none"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    className="w-6 h-6 text-gray-600"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                        ></path>
                                </svg>
                            </button> */}
                                <button
                                    type="button"
                                    className="inline-flex items-center justify-center px-4 py-3 text-white transition duration-500 ease-in-out bg-blue-500 rounded-lg hover:bg-blue-400 focus:outline-none"
                                    onClick={() => {
                                        setHasSelectedFiles(false);
                                        sendMessage();
                                        setFiles(null);
                                    }}
                                >
                                    <span className="font-bold">Send</span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        className="w-6 h-6 ml-2 transform rotate-90"
                                    >
                                        <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                                    </svg>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    },
);

export default ChatSession;

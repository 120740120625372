/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from "axios";
import "./i18n";
// @ts-ignore
window.axios = axios;
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

/* import Echo from "laravel-echo";
import Pusher from "pusher-js";

window.Pusher = Pusher;

console.log(import.meta.env);

console.log("minor change");

const options = {
    broadcaster: "pusher",
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER ?? "us2",
};

window.Echo = new Echo({
    ...options,
    client: new Pusher(options.key, options),
    forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? "https") === "https",
    authEndpoint: "/pusher/auth",
});

window.Echo.private("hello-world").listen("HelloWorld", (e) => {
    console.log(e);
});
 */

import React, { useState, useEffect, useRef } from "react";
import ChatWidget from "../Components/ChatWidget";
import ChatSession from "../Components/ChatSession";
import { useSpring, animated } from "react-spring";
import Modal from "../Components/Modal";
import SearchInput from "../Components/SearchInput";
import debounce from "lodash/debounce";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { router } from "@inertiajs/react";
import BlockCard from "../BlockCard";
import { useAtom } from "jotai";
import {
    activeChatAtom,
    chatMessagesAtom,
    isChatVisibleAtom,
    nameAtom,
    sessionIdAtom,
    toAtom,
    newMessageAtom,
} from "../Jotai/ChatSessionAtoms";
import fetchChatSession from "../Utilities/fetchChatSession";
import SearchChatContactContext from "../Context/SearchChatContactContext";
import useWebSocket from "../Utilities/useWebSocket";
import { v4 as uuidv4 } from "uuid";
import FontSizeSlider from "../Components/FontSizeSlider";
// import customToastSuccess from "../Utilities/customToastSuccess";

const GlobalWrapper = ({ children, user }) => {
    const [fontSize, setFontSize] = useState(
        Number.parseInt(localStorage.getItem("font-size"), 10) || 100,
    );

    const handleFontSizeChange = (event) => {
        const newSize = event.target.value;
        setFontSize(newSize);
        // document.documentElement.style.fontSize = `${newSize}%`;
        localStorage.setItem("font-size", newSize);
    };
    // const [toastMessage, setToastMessage] = useState({});
    useWebSocket("wss://websocket-staging.sandoval-test.online");
    useWebSocket("wss://websocket-staging-2.sandoval-test.online");
    // console.log(
    //     "The Websocket connections have been temporarily disabled pending a migration to Laravel Reverb.",
    // );
    // useWebSocket("wss://websocket-staging-3.sandoval-test.online");
    const [showSearchModal, setShowSearchModal] = useState(false);
    const [availableChatContacts, setAvailableChatContacts] = useState([]);

    const [activeChat, setActiveChat] = useAtom(activeChatAtom);
    const [chatMessages, setChatMessages] = useAtom(chatMessagesAtom);
    const [newMessage, setNewMessage] = useAtom(newMessageAtom);
    const [isChatVisible, setIsChatVisible] = useAtom(isChatVisibleAtom);
    const [to, setTo] = useAtom(toAtom);
    const [name, setName] = useAtom(nameAtom);
    const setSessionId = useAtom(sessionIdAtom)[1];
    const [files, setFiles] = useState([]);
    const [fileInputCheck, setFileInputCheck] = useState(false);

    const modalStyle = useSpring({
        transform: isChatVisible
            ? "translate(-50%, -50%)"
            : "translate(-50%, -200%)",
    });

    // useEffect(() => {
    //     document.documentElement.style.fontSize = `${fontSize}%`;
    //     return () => {
    //         document.documentElement.style.fontSize = ''; // Reset on unmount
    //     };
    // }, []);

    useEffect(() => {
        try {
            if (user) {
                axios.get("/get-chat-contacts").then((response) => {
                    console.log({ response });
                    const data = response?.data;
                    setAvailableChatContacts(data);
                });
            }
        } catch (error) {
            console.error("Failed to check if user is logged in.");
            console.log(error);
        }
    }, [user]);

    const searchInputRef = useRef(null);

    const debounceSearch = (event) => {
        const { value: search } = event.target;

        debounce(async () => {
            const response = await axios.post("/search-chat-contact", {
                search,
            });

            const data = response?.data;

            console.log({ searchContactData: data });
            setAvailableChatContacts(data ?? [{}]);
        }, 1000)();
    };

    useEffect(() => {
        let logoutTimer;

        const checkLoggedIn = async () => {
            try {
                const checkIsLoggedInResponse =
                    await axios.get("/is-logged-in");
                const data = checkIsLoggedInResponse?.data;
                const isHomePage = window?.location?.href?.includes("/");
                const isLoginPage = window?.location?.href?.includes("/login");

                if (data?.isLoggedIn) {
                    console.log(
                        "The login check feature is working as expected.",
                    );
                    const { data } = await axios.get("/get-chat-contacts");
                    setAvailableChatContacts(data);
                } else {
                    if (isHomePage || isLoginPage) return;

                    const logoutPromise = new Promise((resolve) => {
                        logoutTimer = setTimeout(() => {
                            router.post("/logout");
                            resolve();
                        }, 2000);
                    });

                    toast.promise(logoutPromise, {
                        loading: "You are about to be logged out in 2 seconds.",
                        duration: 1000,
                    });
                }
            } catch (error) {
                console.error("Failed to fetch all contacts:", error);
            }
        };

        const checkLoggedInInterval = setInterval(() => {
            checkLoggedIn();
        }, 10000);

        return () => {
            if (checkLoggedInInterval) {
                clearInterval(checkLoggedInInterval);
            }
            if (logoutTimer) {
                clearTimeout(logoutTimer);
            }
        };
    }, []);

    // biome-ignore lint/correctness/useExhaustiveDependencies: false positive
    useEffect(() => {
        if (!to) {
            return;
        }

        // Set up an interval to poll every second
        const intervalId = setInterval(async () => {
            try {
                // Make a POST request to the endpoint
                const response = await axios.post(
                    "/latest-chat-session-with-phone-number",
                    { to },
                );

                // Update the chat messages with the latest message
                setChatMessages((prevChatMessages) => {
                    const { chat_session, chat_session_file } = response.data;

                    const newChatMessage = {
                        ...chat_session,
                    };

                    newChatMessage.file_path = chat_session_file;

                    const messageExists = prevChatMessages.some(
                        (msg) => msg.uuid === newChatMessage.uuid,
                    );

                    if (!messageExists) {
                        // If the message doesn't exist, add it
                        return [...prevChatMessages, newChatMessage];
                    }

                    // If the message exists, don't add it
                    return prevChatMessages;
                });
            } catch (error) {
                console.error(error);
            }
        }, 1000); // 1000 milliseconds = 1 second

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, [to]);

    useEffect(() => {
        const chatsSortedByTime = chatMessages.toSorted((a, b) => {
            return a.last_message_time > b.last_message_time ? -1 : 1;
        });

        const latestChat = chatsSortedByTime[0];
    }, [chatMessages]);

    const sendMessageOnPressEnter = (event) => {
        console.log({ event, eventCharCode: event.charCode });
        if (event.key === "Enter") {
            sendMessage();
        } else {
            return;
        }
    };

    const fileInputRef = useRef(null);
    const chatInputRef = useRef(null);

    const refs = {
        chatInputRef,
        fileInputRef,
    };

    const openFilePicker = () => {
        fileInputRef.current.click();

        if (fileInputRef.current.value) {
            setFileInputCheck(true);
        }
    };

    const sendMessage = async () => {
        if (activeChat && to) {
            console.log({ files });

            const file = files?.[0] ? files[0] : {};

            const file_type = file?.type;

            console.log({ file, file_type });

            const message = await axios.post(
                "/send-message",
                {
                    session_id: activeChat,
                    message: newMessage ?? "",
                    to,
                    user_id: user?.id,
                    files,
                    file_type,
                },
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                },
            );

            if (message?.data?.chat_session?.status_code === 403) {
                toast.error(
                    "The recipient's phone number is not accessible via WhatsApp.",
                );
                return;
            }

            const filesFromMessage = message?.data?.chat_session_files;

            console.log({ filesFromMessage });

            setChatMessages((prevChatMessages) => [
                ...prevChatMessages,
                {
                    last_message: newMessage,
                    last_message_time: new Date().toISOString(),
                    user_id: user?.id,
                    to: message?.data?.chat_session?.to,
                    session_id: message?.data?.chat_session?.session_id,
                    id: message?.data?.chat_session?.id,
                    updated_at: message?.data?.chat_session?.updated_at,
                    created_at: message?.data?.chat_session?.created_at,
                    file_path: filesFromMessage
                        ? filesFromMessage[0]
                        : undefined,
                },
            ]);
            setNewMessage("");
        }
    };

    const closeModal = () => {
        setIsChatVisible(false);
    };

    useEffect(() => {
        if (showSearchModal && searchInputRef) {
            searchInputRef?.current?.focus();
        }
    }, [showSearchModal]);

    if (!user) {
        return <>{children}</>;
    }

    // if (toastMessage) {
    //     customToastSuccess(toastMessage, { duration: toastMessage?.duration ?? 5000 });
    // }

    return (
        <>
            {/* <FontSizeSlider fontSize={fontSize} className="absolute text-xl right-4 top-4 w-96" onChange={handleFontSizeChange} /> */}
            <Toaster />

            <div style={{ fontSize: `${fontSize}% !important` }}>
                {children}
                <div className="fixed z-30 flex flex-col p-4 bg-white rounded-full shadow-xl right-4 bottom-4">
                    <SearchChatContactContext.Provider
                        value={setShowSearchModal}
                    >
                        <ChatWidget
                            className="in-chat-widget"
                            fetchChatSession={fetchChatSession}
                            name={name}
                        />
                    </SearchChatContactContext.Provider>
                </div>
                <animated.div
                    style={modalStyle}
                    className="fixed top-[50%] left-[50%] w-[80%] h-[70%] bg-white shadow-xl z-50"
                >
                    <ChatSession
                        chatMessages={chatMessages}
                        newMessage={newMessage}
                        setNewMessage={setNewMessage}
                        sendMessage={sendMessage}
                        sendMessageOnPressEnter={sendMessageOnPressEnter}
                        openFilePicker={openFilePicker}
                        setFiles={setFiles}
                        closeModal={closeModal}
                        to={to}
                        user={user}
                        name={name}
                        ref={refs}
                        fileInputCheck={fileInputCheck}
                    />
                </animated.div>
                <Modal
                    show={showSearchModal}
                    onClose={() => {
                        setShowSearchModal(false);
                    }}
                    className="fixed inset-0 z-50 flex flex-col items-center justify-center w-3/4 px-4 py-6 mx-auto my-auto overflow-y-auto transition-all transform bg-white border rounded-sm shadow-md h-4/5 sm:px-0"
                    dialogPanelClassName="fixed inset-0 z-50 flex flex-col items-center justify-center w-[95%] p-4 mx-auto overflow-y-auto transition-all transform sm:px-0"
                    overlayClassName="fixed inset-0"
                >
                    <SearchInput
                        ref={searchInputRef}
                        onChange={debounceSearch}
                    />
                    {/* // Map over availableChatContacts with the Card component */}
                    <div className="overflow-y-auto h-[628px] w-full">
                        {Array.isArray(availableChatContacts) &&
                            availableChatContacts.map((contact) => {
                                const { phone_number: phoneNumber } = contact;

                                // Strip all non-numeric characters from the phone number

                                const formattedPhoneNumber =
                                    phoneNumber.replace(/\D/g, "");

                                return (
                                    <BlockCard
                                        key={contact.id}
                                        title={`${contact.first_name} ${contact.last_name}`}
                                        titleClassName="flex align-center gap-2 text-xl leading-7 font-bold p-4"
                                        titleContClassName="flex flex-col align-items flex-1 gap-2"
                                        // phoneNumber={formattedPhoneNumber}

                                        onClick={() => {
                                            setShowSearchModal(false);
                                            console.log(
                                                "Accessing chat from search result",
                                            );
                                            // createNewChatSession(formattedPhoneNumber);

                                            // Generate UUID

                                            const uuid = uuidv4();

                                            fetchChatSession({
                                                sessionIdArg: uuid,
                                                toArg: formattedPhoneNumber,
                                                setIsChatVisible,
                                                setActiveChat,
                                                setChatMessages,
                                                setTo,
                                                setName,
                                                setSessionId,
                                            });
                                        }}
                                        className="w-auto h-[60px] my-4 text-white cursor-pointer card bg-sandoval-green rounded-md justify-start align-center"
                                    />
                                );
                            })}
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default GlobalWrapper;

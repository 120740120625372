import { useState, useEffect, useCallback } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import customToastSuccess from "./customToastSuccess";
import { io } from "socket.io-client";

// Custom hook for WebSocket connection
const useWebSocket = (url, setToastMessage = () => { }) => {
    const [socket, setSocket] = useState(null);

    const connect = useCallback(() => {
        console.log("Attempting Socket.IO connection");
        const newSocket = io(url, {
            transports: ['polling'],
            pingTimeout: 60000, // 60 seconds
            pingInterval: 25000, // 25 seconds
            reconnection: true,
            reconnectionAttempts: Number.Infinity,
            reconnectionDelay: 1000,
            debug: true,
        });

        newSocket.on("connect", () => {
            console.log("Socket.IO connection established");
            newSocket.emit("hello", "Socket.IO opened for job-time-remaining endpoint!");
            newSocket.emit("ping");
            console.log("Socket.IO ping sent");
        });

        newSocket.on("pong", () => {
            console.log("Socket.IO pong received");
        });

        newSocket.on("message", (message) => {
            handleMessage(message);
        });

        newSocket.on("connect_error", (error) => {
            console.error("Socket.IO connection error:", error);
        });

        newSocket.on("disconnect", (reason) => {
            console.log("Socket.IO connection closed:", reason);
            // Socket.IO will automatically try to reconnect
        });

        setSocket(newSocket);
    }, [url]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: false positive
    useEffect(() => {
        connect();
        return () => {
            if (socket) {
                console.log("about to close socket");
                socket.close();
                socket.disconnect();
            }
        };
    }, [connect]);

    const handleMessage = (message) => {
        console.log("Received message:", message);
        const { type, data } = message;

        console.log({ messageType: type, messageData: data });

        switch (type) {
            case "job-time-remaining":
                handleJobTimeRemaining(data);
                break;
            case "job-active":
                handleNotifyJobActive(data);
                break;
            case "job-past-due":
                handleNotifyJobPastDue(data);
                break;
            case "job-cancelled":
                handleNotifyJobCancelled(data);
                break;
            case "job-completed":
                handleNotifyJobCompleted(data);
                break;
            case "task-time-remaining":
                handleTaskTimeRemaining(data);
                break;
            case "task-active":
                handleNotifyTaskActive(data);
                break;
            case "task-past-due":
                handleNotifyTaskPastDue(data);
                break;
            case "task-cancelled":
                handleNotifyTaskCancelled(data);
                break;
            case "task-completed":
                handleNotifyTaskCompleted(data);
                break;
            case "upload-before-job-image-completed":
                handleUploadBeforeImageJobCompleted(data);
                break;
            case "upload-after-job-image-completed":
                handleUploadAfterImageJobCompleted(data);
                break;
            case "notify-submit-incident":
                handleNotifySubmitIncident(data);
                break;
            case "crew-has-job":
                handleCrewHasJob(data);
                break;
            default:
                console.log("Unhandled message type:", type);
        }
    };

    const handleCrewHasJob = async (data) => {
        console.log("Crew Has Job:", data);
        const { crewName, jobName, teamLeadName, teamLeadPhoneNumber } = data;
        toast(`${crewName} has already been assigned to ${jobName}. 
            Team lead: ${teamLeadName ?? "Team lead not found"}
            Team lead phone number: ${teamLeadPhoneNumber ?? "N/A"}
        `, { icon: "🚨" });
    };


    const handleNotifySubmitIncident = async (data) => {
        const { employeeName, teamLeadName, incidentName, incidentId } = data;

        console.log({ data });

        console.log({ employeeName, teamLeadName, incidentName });

        const duration = 5000;

        if (employeeName) {
            customToastSuccess(
                {
                    message: `Incident "${incidentName}" has been submitted successfully by ${employeeName}!`,
                    id: incidentId,
                },
                {
                    duration,
                },
            );
        }

        if (teamLeadName) {
            customToastSuccess(
                {
                    message: `Incident "${incidentName}" has been submitted successfully by ${teamLeadName}!`,
                    id: incidentId,
                },
                {
                    duration,
                },
            );
        }
    };

    // const handleNotifyUploadIncidentImage = (data) => {
    //     console.log("Notify Upload Incident Image:", data);

    //     const { job_name, team_lead_name, metadata } = data;

    //     console.log({ metadata });

    //     toast.success(
    //         `An incident for ${job_name} has been submitted successfully by ${team_lead_name}!`,
    //     );
    // };

    const handleUploadBeforeImageJobCompleted = async (data) => {
        console.log("Data:", data);

        const { job_id } = data;

        try {
            const response = await axios.get(`/job/${job_id}`);

            if (response.status === 200) {
                const job = response.data;
                console.log("Job:", job);
                toast.success(`Before image uploaded for job: ${job.name}`);
            }
        } catch (error) {
            console.error("Error uploading before image:", error);
        }
    };

    const handleUploadAfterImageJobCompleted = async (data) => {
        const { job_id } = data;

        try {
            const response = await axios.get(`/job/${job_id}`);

            if (response.status === 200) {
                const job = response.data;
                console.log("Job:", job);
                toast.success(`After image uploaded for job: ${job.name}`);
            }
        } catch (error) {
            console.error("Error uploading after image:", error);
        }
    };

    const handleNotifyJobActive = (data) => {
        const { job_id, job_name, job_status } = data;
        toast.success(`${job_name} is now active!`);
    };

    const handleNotifyJobPastDue = (data) => {
        const { job_id, job_name, job_status } = data;
        toast.error(`${job_name} is past due!`);
    };

    const handleNotifyJobCancelled = (data) => {
        const { job_id, job_name, job_status } = data;
        toast.error(`${job_name} has been cancelled`);
    };

    const handleNotifyJobCompleted = (data) => {
        const { job_id, job_name, job_status } = data;
        toast.success(`${job_name} has been completed!`);
    };

    const handleNotifyTaskActive = (data) => {
        const { task_name } = data;
        toast.success(`${task_name} is now active!`);
    };

    const handleNotifyTaskPastDue = (data) => {
        const { task_name } = data;
        toast.error(`${task_name} is past due!`);
    };

    const handleNotifyTaskCancelled = (data) => {
        const { task_name } = data;
        toast.error(`${task_name} has been cancelled`);
    };

    const handleNotifyTaskCompleted = (data) => {
        const { task_id, task_name, task_status } = data;
        toast.success(`${task_name} has been completed!`);
    };

    const handleJobTimeRemaining = (data) => {
        console.log({ data });
        const {
            time_remaining,
            job_name,
            team_lead_name,
            team_lead_phone_number,
        } = data;
        toast.success(
            `${job_name} has ${time_remaining} remaining! Team lead: ${team_lead_name ?? "TBD"} (${team_lead_phone_number})`,
        );
    };

    const handleTaskTimeRemaining = (data) => {
        console.log({ data });
        const {
            time_remaining,
            task_name,
            team_lead_name,
            team_lead_phone_number,
        } = data;
        toast.success(
            `${task_name} has ${time_remaining} remaining! Team lead: ${team_lead_name ?? "TBD"} (${team_lead_phone_number})`,
        );
    };

    const handleJobStatusUpdate = (data) => {
        const { status, name: jobName } = data;
        switch (status) {
            case "PENDING":
                toast.success(`${jobName} is pending!`, { duration: 5000 });
                break;
            case "ACTIVE":
                toast.success(`${jobName} is active!`, { duration: 5000 });
                break;
            case "PAST_DUE":
                toast.error(`${jobName} is past due!`, { duration: 5000 });
                break;
            case "CANCELLED":
                toast.error(`${jobName} has been cancelled!`, {
                    duration: 5000,
                });
                break;
            case "COMPLETED":
                toast.success(`${jobName} has been completed!`, {
                    duration: 5000,
                });
                break;
            default:
                console.warn("Unknown job status:", status);
        }
    };

    return socket;
};

export default useWebSocket;

import {
    Dialog,
    Transition,
    TransitionChild,
    DialogPanel,
} from "@headlessui/react";
import { Fragment, useEffect } from "react";

const Modal = ({
    children,
    show = false,
    height = "auto",
    closeable = true,
    onClose = () => {},
    className = "",
    // className = "p-8 mb-6 overflow-hidden transition-all transform bg-white rounded-lg shadow-xl dark:bg-gray-800 sm:w-full sm:mx-auto",
    // className = "fixed inset-0 z-50 flex items-center px-4 py-6 overflow-y-auto transition-all transform sm:px-0",
    dialogPanelClassName = "fixed inset-0 z-50 flex items-center px-4 py-6 overflow-y-auto transition-all transform sm:px-0",
    overlayClassName = "absolute inset-0 dark:bg-sandoval-green/75",
}) => {
    const close = () => {
        if (closeable) {
            onClose();
        }
    };

    useEffect(() => {
        console.log({ show, className });
    }, [show, className]);

    return (
        <Transition show={show} as={Fragment} leave="duration-200">
            <Dialog as="div" id="modal" className={className} onClose={close}>
                {/* Overlay */}
                <TransitionChild
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    {overlayClassName && <div className={overlayClassName} />}
                </TransitionChild>

                {/* Modal content */}
                <TransitionChild
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <DialogPanel className={dialogPanelClassName}>
                        {/* Close button */}
                        {closeable && (
                            <button
                                type="button"
                                onClick={close}
                                aria-label="Close modal"
                                className="absolute z-50 text-gray-700 top-4 right-4 dark:text-gray-300 focus:outline-none"
                            >
                                ✕
                            </button>
                        )}

                        {children}
                    </DialogPanel>
                </TransitionChild>
            </Dialog>
        </Transition>
    );
};

export default Modal;

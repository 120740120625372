import "../css/app.css";
import "./bootstrap";

import { createInertiaApp } from "@inertiajs/react";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { createRoot } from "react-dom/client";
import GlobalWrapper from "./Layouts/GlobalWrapper";
import React from "react";
import { Provider } from "jotai";

const appName = import.meta.env.APP_NAME || "Laravel";

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    setup({ el, App, props }) {
        const root = createRoot(el);
        root.render(
            <Provider>
                <App {...props} />
            </Provider>,
        );
    },
    progress: {
        color: "#4B5563",
    },

    resolve: async (name) => {
        const page = await resolvePageComponent(
            `./Pages/${name}.jsx`,
            import.meta.glob("./Pages/**/*.jsx"),
        );

        page.default.layout = (page, props) => {
            const { user } = page?.props?.auth ?? {};
            return <GlobalWrapper user={user}>{page}</GlobalWrapper>;
        };
        return page;
    },
});

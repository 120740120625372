import axios from "axios";
const fetchChatSession = async ({
    sessionIdArg,
    toArg,
    setActiveChat,
    setChatMessages,
    setTo,
    setName,
    setSessionId,
    setIsChatVisible,
    // setUnreadMessageCount,
}) => {
    try {
        const sessionResponse = await axios.post("/chat-session", {
            session_id: sessionIdArg,
            to: toArg,
            from: toArg,
        });

        setSessionId(sessionIdArg);

        const { data: sessionResponseData } = sessionResponse;

        const { from, to } = sessionResponseData[0];

        const sessionResponseDataSortedByLastMessageTime =
            sessionResponseData.toSorted((a, b) => {
                return (
                    new Date(a.last_message_time) -
                    new Date(b.last_message_time)
                );
            });

        setChatMessages(sessionResponseDataSortedByLastMessageTime);
        setActiveChat(sessionIdArg);
        setTo(to ?? from);
        setIsChatVisible(true);

        const employeeOrTeamLeadByPhone = await axios.post(
            "/employee-team-lead/phone",
            {
                phone_number: to ?? from,
            },
        );

        const { employee, teamLead } = employeeOrTeamLeadByPhone?.data;

        if (employee) {
            const { first_name, last_name } = employee;
            setName(first_name + " " + last_name);
        }

        if (teamLead) {
            const { first_name, last_name } = teamLead;
            setName(first_name + " " + last_name);
        }
    } catch (error) {
        console.error("Failed to fetch chat session:", error);
    }
};

export default fetchChatSession;
